import { FUNTRABIcon } from "@/components/ui/custom-icon/FUNTRABIcon"
import { IEPIcon } from "@/components/ui/custom-icon/IEPIcon"
import { MSArmsIcon } from "@/components/ui/custom-icon/MSArmsIcon"
import { EnumData } from "@/interfaces/EnumData"
import { UserRoleAttributes, userRoleConsts } from "@/interfaces/constants/UserRoleConsts"
import { UserRoles } from "@/interfaces/enums/UserRoles"
import { CurrentProfile } from "@/interfaces/profile/CurrentProfile"
import { ProfileOutput } from "@/interfaces/profile/ProfileOutput"
import { AdminPanelSettings, Business, School } from "@mui/icons-material"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"

/** ------------------------------------------------------------------
 *  MAPEAMENTOS
 * ------------------------------------------------------------------ */

/**
 * Mapeia cada role para seu ícone correspondente.
 */
export const UserRolesIcon: Record<UserRoles, JSX.Element> = {
  [UserRoles.ADMIN]: <AdminPanelSettings />,
  [UserRoles.COMPANY]: <Business />,
  [UserRoles.CANDIDATE]: <AccountCircleIcon />,
  [UserRoles.STUDENT]: <School />,
  [UserRoles.FUNTRAB]: <FUNTRABIcon />,
  [UserRoles.SEMADESC]: <MSArmsIcon />,
  [UserRoles.ADMIN_IEP]: <IEPIcon />,
  [UserRoles.SECRETARY_IEP]: <IEPIcon />,
}

/**
 * Mapeia cada role para seu nome (label) de exibição.
 */
const USER_ROLES_NAME_MAP: Record<UserRoles, string> = {
  [UserRoles.ADMIN]: "ADMIN",
  [UserRoles.COMPANY]: "EMPRESA",
  [UserRoles.CANDIDATE]: "CANDIDATO",
  [UserRoles.STUDENT]: "ALUNO",
  [UserRoles.FUNTRAB]: "FUNTRAB",
  [UserRoles.SEMADESC]: "SEMADESC",
  [UserRoles.ADMIN_IEP]: "ADMIN IEP",
  [UserRoles.SECRETARY_IEP]: "SECRETARIA IEP",
}

/** ------------------------------------------------------------------
 *  VERIFICAÇÕES DE ROLE (IS / HAS)
 * ------------------------------------------------------------------ */

/**
 * Verifica se um único perfil (`CurrentProfile`) possui um role específico.
 */
function isProfileRole(profile: CurrentProfile | null, role: UserRoles): boolean {
  return profile?.role?.value === role
}

/**
 * Cria funções específicas de verificação para cada role (single profile).
 */
export function isCompanyProfileRole(profile: CurrentProfile | null) {
  return isProfileRole(profile, UserRoles.COMPANY)
}
export function isCandidateProfileRole(profile: CurrentProfile | null) {
  return isProfileRole(profile, UserRoles.CANDIDATE)
}
export function isADMINProfileRole(profile: CurrentProfile | null) {
  return isProfileRole(profile, UserRoles.ADMIN)
}
export function isFuntrabProfileRole(profile: CurrentProfile | null) {
  return isProfileRole(profile, UserRoles.FUNTRAB)
}
export function isStudentProfileRole(profile: CurrentProfile | null) {
  return isProfileRole(profile, UserRoles.STUDENT)
}
export function isSemadescProfileRole(profile: CurrentProfile | null) {
  return isProfileRole(profile, UserRoles.SEMADESC)
}
export function isAdminIepProfileRole(profile: CurrentProfile | null) {
  return isProfileRole(profile, UserRoles.ADMIN_IEP)
}
export function isSecretaryIepProfileRole(profile: CurrentProfile | null) {
  return isProfileRole(profile, UserRoles.SECRETARY_IEP)
}

/**
 * Verifica se a lista de perfis (`ProfileOutput[]`) contém um role específico.
 */
function hasProfileRole(profiles: ProfileOutput[] | undefined, role: UserRoles): boolean {
  return profiles?.some((p) => p.role.value === role) || false
}

/**
 * Cria funções específicas de verificação para cada role (lista de profiles).
 */
export function hasFuntrabProfileRole(profiles: ProfileOutput[] | undefined) {
  return hasProfileRole(profiles, UserRoles.FUNTRAB)
}
export function hasSemadescProfileRole(profiles: ProfileOutput[] | undefined) {
  return hasProfileRole(profiles, UserRoles.SEMADESC)
}
export function hasAdminIepProfileRole(profiles: ProfileOutput[] | undefined) {
  return hasProfileRole(profiles, UserRoles.ADMIN_IEP)
}
export function hasSecretaryIepProfileRole(profiles: ProfileOutput[] | undefined) {
  return hasProfileRole(profiles, UserRoles.SECRETARY_IEP)
}
export function hasADMINProfileRole(profiles: ProfileOutput[] | undefined) {
  return hasProfileRole(profiles, UserRoles.ADMIN)
}
export function hasStudentProfileRole(profiles: ProfileOutput[] | undefined) {
  return hasProfileRole(profiles, UserRoles.STUDENT)
}

/**
 * Exemplo separado, pois não envolve `UserRoles` diretamente, mas subRoles.
 */
export function hasAdminFruntrabSubRole(subRoles: EnumData[] | undefined): boolean {
  return subRoles?.some((subrole) => subrole.value === "ADMIN_FUNTRAB") ?? false
}

/** ------------------------------------------------------------------
 *  OBTENÇÃO DE ÍCONE, LABEL E PERFIL
 * ------------------------------------------------------------------ */

/**
 * Retorna o ícone correspondente ao perfil informado ou `null` se não existir.
 */
export function getProfileIcon(profile: CurrentProfile | null): JSX.Element | null {
  if (!profile?.role?.value) return null
  return UserRolesIcon[profile.role.value] || null
}

/**
 * Retorna o nome (label) correspondente ao perfil informado. Se não existir, retorna string vazia.
 */
export function getProfileTextName(profile: CurrentProfile | null): string {
  if (!profile?.role?.value) return ""
  return USER_ROLES_NAME_MAP[profile.role.value] ?? ""
}

/**
 * Dada uma lista de perfis, retorna aquele que corresponde ao `profileId` (que pode ser o ID ou a role).
 */
export function getProfileById(
  profiles: CurrentProfile[] | undefined,
  profileId: string = "",
): CurrentProfile | null {
  if (!profiles || !profileId) return null

  return (
    profiles.find(
      (profile) =>
        String(profile.id) === String(profileId) ||
        String(profile.role.value) === String(profileId),
    ) || null
  )
}

/** ------------------------------------------------------------------
 *  OBTENÇÃO DE ROLES POR PESO
 * ------------------------------------------------------------------ */

/**
 * Retorna o objeto `UserRoleAttributes` do role de maior peso em uma lista de perfis.
 * Exemplo de UserRoleAttributes: { weight: number; defaultPanel?: string; ... }
 */
export function getHighestWeightedRole(
  profiles: ProfileOutput[],
): UserRoleAttributes | null {
  if (profiles.length === 0) return null

  return profiles.reduce<UserRoleAttributes | null>((acc, profile) => {
    const role = profile.role.value as UserRoles
    const roleAttr = userRoleConsts[role]
    if (!roleAttr) return acc

    if (!acc || roleAttr.weight > acc.weight) {
      return roleAttr
    }
    return acc
  }, null)
}

/**
 * Retorna a defaultPanel (string) do role de maior peso.
 * Se não houver role, ou não tiver defaultPanel, retorna null.
 */
export function getDefaultPanelOfHighestWeightedRole(
  profiles: ProfileOutput[],
): string | null {
  const highestRoleAttributes = getHighestWeightedRole(profiles)
  return highestRoleAttributes?.defaultPanel ?? null
}
